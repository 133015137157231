import React from "react"
import { storyblokEditable } from '@storyblok/js'

function CallToAction( {blok}) {
    return (

        <div {...storyblokEditable(blok)}>
            <div className="mt-5 sm:mt-8 sm:flex sm:justify-center lg:justify-start">
                <div className="rounded-md shadow">
                  <a
                    href="#contactform"
                    className="w-full flex items-center justify-center px-8 py-3 border border-transparent text-base font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 md:py-4 md:text-lg md:px-10"
                  >
                    Get Started
                    </a>
                </div>
            </div>
        </div>
    )
}

export default CallToAction