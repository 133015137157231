module.exports = {
  siteMetadata: {
    title: `Grumeti Media`,
    description: `We build lightning fast websites on the JAMStack and Headless CMS. Take your journey with us.`,
    author: `@tykamal`,
    siteUrl: `https://www.grumetimedia.co.tz/`,
  },
  plugins: [
    `gatsby-plugin-react-helmet`,
    `gatsby-plugin-image`,
    {
      resolve: `gatsby-source-filesystem`,
      options: {
        name: `images`,
        path: `${__dirname}/src/images`,
      },
    },
    `gatsby-transformer-sharp`,
    `gatsby-plugin-sharp`,
    `gatsby-plugin-postcss`,
    {
      resolve: `gatsby-plugin-manifest`,
      options: {
        name: `gatsby-starter-default`,
        short_name: `starter`,
        start_url: `/`,
        background_color: `#663399`,
        // This will impact how browsers show your PWA/website
        // https://css-tricks.com/meta-theme-color-and-trickery/
        // theme_color: `#663399`,
        display: `minimal-ui`,
        icon: `src/images/gatsby-icon.png`, // This path is relative to the root of the site.
      },
    },
    {
      resolve: 'gatsby-source-storyblok',
      options: {
        accessToken: 'kIGPR2mvKezMvclAqD5ukgtt',
        version: process.env.NODE_ENV === 'production' ? 'published' : 'draft',
        localAssets: true, //Optional parameter to download the images to use with Gatsby Image Plugin
        //languages: ['en','sw'] // Optional parameter. Ommission will retrieve all languages by default
       }
    },
    // this (optional) plugin enables Progressive Web App + Offline functionality
    // To learn more, visit: https://gatsby.dev/offline
    // `gatsby-plugin-offline`,
  ],
}
