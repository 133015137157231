import React from 'react'
import { render } from "storyblok-rich-text-react-renderer"
import { storyblokEditable } from "@storyblok/js";
import DynamicComponent from "./DynamicComponent"
import { StaticImage } from 'gatsby-plugin-image'

const Teaser = ({ blok }) => {
  const body = blok &&
    blok.body &&
    blok.body.map(childBlok => <DynamicComponent blok={childBlok} key={childBlok._uid} />)
  const introText = typeof blok.intro_text === 'string' ? blok.intro_text : render(blok.intro_text)
  // const key = typeof blok._editable !== 'undefined' ? blok._uid + '-edit' : blok._uid
  return (
    <div {...storyblokEditable(blok)} className="grid grid-cols-1">
      <StaticImage
        layout="fullWidth"
        aspectRatio={3 / 1}
        alt=""
        src={"https://a.storyblok.com/f/150446/2996x1174/7ddd060248/dar-es-salaam.jpg"}
        formats={["auto", "webp", "avif"]}
        className="[gridArea:1/1] max-h-fit"
      />
      <div className="bg-white-half [gridArea:1/1]">
        <div className="pb-6 pt-16 container mx-auto relative">
          <h1 className="text-4xl font-bold font-sans uppercase text-white">{blok.headline}</h1>
          <div className="text-white text-xl max-w-lg">{introText}</div>
        </div>
        <div className="container mx-auto overflow-x-scroll flex w-full my-8 snap-x relative [gridArea:1/1]">
          {body}
        </div>
      </div>
    </div>
  )
}

export default Teaser
